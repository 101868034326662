import './App.css';
import {Main} from "./components/main";

function App() {

  return (
      <div className="App">
        <Main/>
      </div>
  );
}

export default App;